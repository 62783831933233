body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px 3px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes blinker {
  50% {
    opacity: 1;
  }
}
@keyframes dot-flashing { {

}
  0% {
    background-color: #fff;
  }
  50%, 100% {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/*.swiper {
  width: 100%;
  height: 100%;
}

.swiper-grid .swiper-slide {
  height: calc((100% - 30px) / 2) !important;
}
*/
.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 23px;
  height: 23px;
  background-color: #fff;
  opacity: .5;
  border-radius: 13px;
  margin: 0 12px !important;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.lf-player-container {
  height: 100%;
}